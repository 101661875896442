<template>
  <div class="containner" :class="is_mobile ? 'm-cx-containner' : 'pc-cx-containner'">
    <div class="cx-new-bg" :class="news_index != -1 ? 'cx-new-bg-op' : ''">
      <img class="cx-bg-img" :src="is_mobile ? bg_m : bg" />
      <div class="cx-bg-tit" v-if="news_index == -1">
        <h1>{{ $t("news.bg_title") }}</h1>
      </div>
      <div class="cx-bg-tip">{{ $t("news.bg_tip") }}</div>
    </div>
    <div class="cx-content">
      <div class="cx-list-con" ref="cx_li" v-if="is_mobile">
        <news-item
          v-for="(item, index) in news_list"
          :key="index"
          @changeNewsInfo="changeInfo"
          :item="item"
          :news_index="index"
          :is_checked="news_index == index ? true : false"
        ></news-item>
      </div>
      <div class="container-left" :style="box_height" v-if="!is_mobile">
        <happy-scroll
          color="rgba(255,255,255)"
          size="4"
          right
          hide-horizontal
          v-if="show_list"
          @vertical-end="loadMore"
        >
          <div class="cx-list-con">
            <news-item
              v-for="(item, index) in news_list"
              :key="index"
              @changeNewsInfo="changeInfo"
              :item="item"
              :news_index="index"
              :is_checked="news_index == index ? true : false"
            ></news-item>
          </div>
        </happy-scroll>
      </div>
      <div
        class="container-right"
        :style="box_height"
        v-if="!is_mobile && news_index != -1"
      >
        <div class="cr-scroll-box" :style="box_info_height">
          <happy-scroll
            color="rgba(255,255,255)"
            resize
            v-if="news_info"
            size="4"
            right
            hide-horizontal
          >
            <div class="news-content">
              <news-info :news_info="news_info" v-if="show_now"></news-info>
            </div>
          </happy-scroll>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NewsItem from "../components/NewsItem.vue";
import NewsInfo from "../components/NewsInfo.vue";
export default {
  name: "News",
  props: {},
  data() {
    return {
      bg: require("@/assets/news-bg.png"),
      bg_m: require("@/assets/news-bg-m.png"),
      window_height: document.documentElement.clientHeight - 100,
      news_index: -1,
      news_list: [],
      news_info: null,
      show_now: false,
      show_list: false,

      current_page: 1,
      last_page: 1,
      total: 8,

      cx_li: null,

      offset_height: 0,
      is_lock:false
    };
  },
  computed: {
    box_height() {
      if (this.is_mobile) {
        return "height:auto";
      } else {
        return "height:" + this.window_height + "px";
      }
    },
    box_info_height() {
      if (this.is_mobile) {
        return "height:auto";
      } else {
        return "height:" + parseInt(this.window_height - 100) + "px";
      }
    },
  },
  methods: {
    changeInfo(index) {
      this.news_index = index;
      if (this.is_mobile) {
        this.$router.push("/news/info/" + this.news_list[index].id);
      } else {
        this.show_now = false;
        this.api.getNewInfo({ id: this.news_list[index].id }).then((res) => {
          if (res.code == 200) {
            this.news_info = res.data;
            this.$nextTick(() => {
              this.show_now = true;
            });
          }
        });
      }
    },
    loadMore() {
      this.current_page++;
      this.getList();
    },
    getList() {
      if (this.current_page > this.last_page) {
        return false;
      }
      if(this.is_lock){
        return false;
      }
      this.is_lock=true;
      this.api
        .getNews({
          page: this.current_page,
        })
        .then((res) => {
          if (res.code == 200) {
            this.news_list = this.news_list.concat(res.data.data);
            this.last_page = res.data.last_page;
            this.current_page = res.data.current_page;
            this.$nextTick(() => {
              this.show_list = true;
              if(this.is_mobile){
                this.offset_height = this.$refs.cx_li.offsetHeight;
              }
              this.is_lock=false;
            });
          }
        });
    },
  },
  components: {
    "news-item": NewsItem,
    "news-info": NewsInfo,
  },
  mounted() {
    let that = this;
    this.getList();
    if (this.is_mobile) {
      window.addEventListener("scroll", function () {
        const scroll_top = that.getScrollTop()+1000;
        if (scroll_top >= that.offset_height) {
          that.loadMore();
        }
      });
    }
  },
};
</script>
<style>
.containner {
  overflow: hidden;
}
.pc-cx-containner {
  margin-top: 0px;
}
.cx-content {
  position: relative;
  width: 1510px;
  margin: 120px auto 0px;
  overflow: hidden;
}

.container-left {
  position: relative;
  width: 575px;
  float: left;
  color: #fff;
  height: 100%;
}

.container-right {
  position: relative;
  width: 910px;
  float: right;
  background-color: rgba(41, 41, 41, 0.9);
}
.cr-scroll-box {
  position: relative;
  width: 890px;
  margin-top: 60px;
}
.news-content {
  position: relative;
  width: 755px;
  margin-left: 55px;
  margin-bottom: 60px;
}
.happy-scroll-strip--vertical {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
}

.cx-list-con {
  padding-bottom: 0px;
}

.cx-new-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}

.cx-bg-img {
  position: absolute;
  width: 1920px;
  height: 1080px;
  left: 0px;
  top: 100px;
}
.cx-new-bg-op {
  opacity: 0.3;
}

.cx-bg-tit {
  position: absolute;
  left: 1350px;
  top: 280px;
  z-index: 1;
}
.cx-bg-tit h1 {
  font-size: 60px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 0px;
  color: rgba(255, 255, 255, 1);
  width: 1000px;
  margin-left: -500px;
  text-align: center;
}

.cx-bg-tip {
  position: absolute;
  left: 1162px;
  top: 350px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 16px;
  line-height: 0px;
  color: rgba(255, 255, 255, 1);
  z-index: 1;
}

.m-cx-containner {
  width: 750px;
  margin-top:100px;
}
.m-cx-containner .cx-content {
  margin-top: 40px;
}
.m-cx-containner .container-left {
  width: 750px;
  height: 950px;
}
.m-cx-containner .cx-list-con {
  margin: 0 auto;
}

.m-cx-containner .cx-new-bg {
  position: relative;
  height: 368px;
}

.m-cx-containner .cx-bg-img {
  position: relative;
  width: 750px;
  height: 368px;
  top:0px;
}

.m-cx-containner .cx-content {
  width: 750px;
  min-height: 600px;
}
.m-cx-containner .cx-bg-tit{
  width:100%;
  left:0px;
  top:90px;
  text-align: center;
}
.m-cx-containner .cx-bg-tit h1{
  width:100%;
  font-size: 44px;
  margin-left:0px;
}
.m-cx-containner .cx-bg-tip{
  top:150px;
  font-size: 14px;
  left:0px;
  width: 100%;
  text-align: center;
}
.happy-scroll-container .happy-scroll-content {
  display: block;
}
</style>
