<template>
  <div class="cx-new" :class="[is_checked?'cx-new-checked':'',is_mobile ? 'm-cx-new' : 'pc-cx-new']" @click="newInfo">
    <div class="cx-new-cover">
      <img :src="item.thumb" />
    </div>
    <div class="cx-n-msg">
      <h1 class="cx-n-title">{{ item[$t('language.shot_name')+'_title'] }}</h1>
      <div class="cx-n-date">{{ item.inputtimedate }}</div>
      <div class="cx-n-desc">
        {{ item[$t('language.shot_name')+'_desc'] }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NewItem",
  props: {
    item:{
      type:Object
    },
    news_index:{
      type:Number
    },
    is_checked:{
      type:Boolean
    }
  },
  data() {
    return {};
  },
  methods: {
    newInfo(){
      this.$emit('changeNewsInfo',this.news_index)
    }
  },
  components: {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.cx-new {
  position: relative;
  width: 549px;
  height: 175.5px;
  background-color: rgba(41, 41, 41, 0.9);
  margin-bottom: 24px;
  cursor: pointer;
  border: rgba(41, 41, 41, 0.9) solid 2px;
}
.cx-new-checked{
  border: 2px solid rgba(144, 218, 252, 1);            
  box-shadow: 0px 2px 15px 1px rgba(144, 218, 252, 1);
  z-index: 111;
}
.cx-new-cover {
  position: relative;
  float: left;
  width: 175.5px;
  height: 175.5px;
  overflow: hidden;
  img{
    position: absolute;
    height: 100%;
    width: auto;
    left:50%;
    top:0px;
    transform: translateX(-50%);
  }
}
.cx-n-msg {
  float: right;
  width: 325px;
  margin-right: 20px;
}

.cx-n-title {
  text-align: left;
  font-size: 18px;
  line-height: 24px;
  margin-top: 26px;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 这里是超出几行省略 */
  overflow: hidden;
  color: #fff;
}
.cx-n-date {
  font-size: 12px;
  line-height: 24px;
  text-align: left;
  margin-top: 3px;
  margin-bottom: 7px;
  color: rgba(186, 186, 186, 1);
}
.cx-n-desc {
  font-size: 14px;
  line-height: 24px;
  color: rgba(186, 186, 186, 1);
  text-align: left;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 这里是超出几行省略 */
  overflow: hidden;
}

.m-cx-new {
  width: 590px;
  height: 591px;
  margin: 31px auto 0px;
  .cx-new-cover {
    float: none;
    width: 590px;
    height: 335.5px;
    margin-bottom: 23px;
    img{
      width: 100%;
      height: auto;
    }
  }
  .cx-n-msg {
    float: none;
    width: 529px;
    margin:0 auto;
  }
  .cx-n-title {
    width: 529px;
    margin: 0 auto;
    font-size: 24px;
    line-height: 36px;
  }
  .cx-n-date{
    font-size: 22px;
    margin:15px 0px;
  }
  .cx-n-desc{
    font-size: 22px;
  }
}
</style>
